import React, { useContext, useEffect } from 'react';
import { Text, Button } from 'react-native';
import { Container, Content, List, ListItem } from 'native-base';
import { CalculationContext } from '../CalculationContext';

function Studies({ matched, navigation }) {
  const { selectStudy } = useContext(CalculationContext);

  const studyClicked = (s) => {
    selectStudy(s);
    navigation.navigate('StudyDetails');
  }

  return (
    <List testID="resultsList"
      dataArray={matched}
      keyExtractor={(obj) => obj.name}
      renderRow={item =>
        <ListItem onPress={() => studyClicked(item)}>
          <Text>{item.name}</Text>
        </ListItem>}
    />
  );
}

function NoStudies() {
  const msg = "No studies match the given criteria"
  return (
    <Text style={{ fontSize: 20, marginBottom: 10, marginTop: 10, textAlign: 'center' }}>{msg}</Text>
  )
}

function ListWrapper({ matched, navigation }) {
  const result = matched.length > 0 ? <Studies matched={matched} navigation={navigation} /> : <NoStudies />;
  return result;
}

export default function Results({ navigation }) {

  const { matched } = useContext(CalculationContext);
  useEffect(() => {
    matched.forEach((study) => plausible(study.name) );
  }, []);

  return (
    <Container>
      <Content>
        <ListWrapper matched={matched} navigation={navigation} />
        <Button title="To Questions" onPress={() => navigation.navigate('Questions')} />
      </Content>
    </Container>
  );
}
