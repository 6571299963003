import React, { useState, useContext } from 'react';
import { 
  Button,
  Text
} from 'react-native';

import {
  Container, Content, Form, Item, List, ListItem,
  Input, Label, Left, Right, Radio
} from 'native-base';
import Slider from '../components/Slider';
import ReverseSlider from '../components/ReverseSlider';
import { CalculationContext } from '../CalculationContext';


import { FormStyles } from './FormStyles';


export default function QuestionsScreen({navigation}) {

  const {studies, answers, answerQuestions} = useContext(CalculationContext);

  console.log("ANSWERS:::", answers);
  const [mechanicalThrombectomyPlanned, setMechanicalThrombectomy] = useState(answers.mechanicalThrombectomyPlanned);
  const [age, setAge] = useState(answers.age || "");
  const [lastNormal, setLastNormal] = useState(answers.lastNormal || "");
  const [nihssScore, setNihssScore] = useState(answers.nihssScore || 0);
  const [nihssSliderValue, setNihssSliderValue] = useState(answers.nihssScore || 0);
  const [aspectScore, setAspectScore] = useState(answers.aspectScore || 10);
  const [aspectScoreSliderValue, setAspectScoreSliderValue] = useState(answers.aspectScore || 0);

  const [largeVesselOcclusion, setLargeVesselOcclusion] = useState(answers.largeVesselOcclusion || "");
  const [tpaGiven, setTpaGiven] = useState(answers.tpaGiven || "");

  const [redirectPath, setRedirectPath] = useState(null);
  const answerMap = () => {
    return {
      age: parseInt(age),
      lastNormal: parseInt(lastNormal),
      nihssScore: parseInt(nihssScore),
      aspectScore: parseInt(aspectScore),
      largeVesselOcclusion: largeVesselOcclusion,
      mechanicalThrombectomyPlanned: mechanicalThrombectomyPlanned,
      tpaGiven: tpaGiven
    };
  }

  const checkClick = () => {
    let answers = answerMap();
    console.log('QUESTIONS: HERE BE ANSWERS')
    console.log(answers);
    let nextPath = answerQuestions(answers);
    navigation.navigate(nextPath);
  }

  return (
    <Container>
      <Content>
        <Form>
          <Item stackedLabel>
            <Label style={FormStyles.label}>Age (years)</Label>
            <Input testID="age" onChangeText={setAge} value={age} keyboardType="number-pad"></Input>
          </Item>
          <Item stackedLabel>
            <Label style={FormStyles.label}>Last Normal (hours)</Label>
            <Input testID="lastNormal" onChangeText={setLastNormal} value={lastNormal} keyboardType="number-pad"></Input> 
          </Item>
            <Slider labelText="NIHSS Score" testID="nihssScore"
              minimumValue={0} 
              maximumValue={42} 
              step={1} 
              onSlidingComplete={setNihssScore}
              thumbTintColor="blue"
              value={nihssScore} />
            <ReverseSlider labelText="ASPECT Score"
              testID="aspectScore"
              minimumValue={0} 
              maximumValue={10} 
              step={1} 
              onValueChange={setAspectScoreSliderValue}
              onSlidingComplete={setAspectScore} 
              value={aspectScore} 
              leftColor="#e9e9e9"
              rightColor="#abe2fb"/>
          <List testID="largeVesselOcclusion">
            <ListItem itemHeader>
              <Text style={FormStyles.label}>Large Vessel Occlusion</Text>
            </ListItem>
            <ListItem onPress={() => setLargeVesselOcclusion("a")}>
              <Left>
                <Text>Yes - anterior</Text>
              </Left>
              <Right>
                <Radio selected={largeVesselOcclusion == "a"} />
              </Right>
            </ListItem>

            <ListItem onPress={() => setLargeVesselOcclusion("b")}>
              <Left>
                <Text>Yes - posterior</Text>
              </Left>
              <Right>
                <Radio selected={largeVesselOcclusion == "b"} />
              </Right>
            </ListItem>

            <ListItem onPress={() => setLargeVesselOcclusion("c")}>
              <Left>
                <Text>Suspected (no images available)</Text>
              </Left>
              <Right>
                <Radio selected={largeVesselOcclusion == "c"} />
              </Right>
            </ListItem>

            <ListItem onPress={() => setLargeVesselOcclusion("d")}>
              <Left>
                <Text>LVO absent on scans</Text>
              </Left>
              <Right>
                <Radio selected={largeVesselOcclusion == "d"} />
              </Right>
            </ListItem>

          </List>
          <List testID="mechanicalThrombectomyPlanned">
            <ListItem itemHeader>
              <Text style={FormStyles.label}>Mechanical Thrombectomy Planned</Text>
            </ListItem>
            <ListItem onPress={() => setMechanicalThrombectomy(true)}>
                <Left><Text>Yes</Text></Left>
                <Right><Radio selected={mechanicalThrombectomyPlanned == true} /></Right>
            </ListItem>
            <ListItem onPress={() => setMechanicalThrombectomy(false)}>
                <Left><Text>No</Text></Left>
                <Right><Radio selected={mechanicalThrombectomyPlanned == false} /></Right>
            </ListItem>
          </List>

          <List testID="tpaGiven">
            <ListItem itemHeader>
              <Text style={FormStyles.label}>tPA Given</Text>
            </ListItem>
            <ListItem onPress={() => setTpaGiven("a")}>
                <Left><Text>Yes within 3 hrs</Text></Left>
                <Right><Radio selected={tpaGiven == "a"} /></Right>
            </ListItem>
            <ListItem onPress={() => setTpaGiven("b")}>
                <Left><Text>Yes 3 - 4.5 hrs</Text></Left>
                <Right><Radio selected={tpaGiven == "b"} /></Right>
            </ListItem>
            <ListItem onPress={() => setTpaGiven("c")}>
                <Left><Text>No</Text></Left>
                <Right><Radio selected={tpaGiven == "c"} /></Right>
            </ListItem>
          </List>
          <Button testID="submitButton" title="Next" onPress={checkClick} />
        </Form>
      </Content>
    </Container>
  );
}

