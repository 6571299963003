import * as React from 'react';

export const CalculationContext = React.createContext({
  studies: [],
  matched: [],
  answers: {},
  answerQuestions: () => {},
  answerClarifications: () => {},
  selectStudy: () => {},
  selectedStudy: () => {}
});
