import * as React from 'react';
import {
  StyleSheet
} from 'react-native';
import { Item, Label, Input } from 'native-base';
import { FormStyles } from '../screens/FormStyles';
import Slider from 'rc-slider';

import 'rc-slider/assets/index.css'
const styles = StyleSheet.create({

  sliderContainerStyle: {
    flex: 1,
    marginLeft: 10,
    marginRight: 10,
    alignItems: "stretch",
    justifyContent: "center"
  },

  sliderStyle: {
    marginLeft: 20,
    marginRight: 20
  }

});

export default function ({ labelText, minimumValue, maximumValue, step, onSlidingComplete,
  value, testID, trackStyle, railStyle, reverse }) {

  const [currentValue, setCurrentValue] = React.useState(value || 0);
  let sliderAdditionalProps = {}

  if (trackStyle) {
    sliderAdditionalProps.trackStyle = trackStyle;
  }

  if (railStyle) {
    sliderAdditionalProps.railStyle = railStyle;
  }

  if (reverse) {
    sliderAdditionalProps.reverse = true;
  }

  return (
    <Item stackedLabel style={styles.sliderContainerStyle} testID={testID}>
      <Label style={FormStyles.label}>{labelText} {currentValue}</Label>
      <Slider
        min={minimumValue}
        max={maximumValue}
        step={step}
        onChange={setCurrentValue}
        onAfterChange={onSlidingComplete}
        defaultValue={value}
        {...sliderAdditionalProps} />
    </Item>
  );
}
