import React, { useContext } from 'react';
import { Text, View } from 'react-native';
import { CalculationContext } from '../CalculationContext';

export default function StudyDetailScreen({ navigation }) {

    const { selectedStudy } = useContext(CalculationContext);
    const study = selectedStudy();

    return (
        <View>
            <Text style={{ fontSize: 20, marginBottom: 10, marginTop: 10, textAlign: 'center' }}>{study.name}</Text>
            <Text>{"PLACEHOLDER: A description would go here"}</Text>
        </View>
    );
}