import * as React from 'react';
import { Platform, StatusBar, StyleSheet, View } from 'react-native';
import { SplashScreen } from 'expo';
import * as Font from 'expo-font';
import { Ionicons } from '@expo/vector-icons';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';

import StackNavigator from './navigation/StackNavigator';
import useLinking from './navigation/useLinking';

import HomeScreen from './screens/HomeScreen';
import LinksScreen from './screens/LinksScreen';
import QuestionsScreen from './screens/QuestionScreen';
import ClarificationScreen from './screens/ClarificationScreen';
import ResultScreen from './screens/ResultScreen';
import StudyDetailScreen from './screens/StudyDetailScreen';
import { matchStudiesGeneral, matchStudiesClarifications, needsClarification } from './screens/calculator';
import { CalculationContext } from './CalculationContext';

import {STUDIES} from './StudyDefinitions'

const Stack = createStackNavigator();

export default function App(props) {
  const [isLoadingComplete, setLoadingComplete] = React.useState(false);
  const [initialNavigationState, setInitialNavigationState] = React.useState();
  const containerRef = React.useRef();
  const { getInitialState } = useLinking(containerRef);
  const [allAnswers, setAnswers] = React.useState({});
  const [matchedStudies, setMatchedStudies] = React.useState([]);
  const [selectedStudy, setSelectedStudy] = React.useState({});

  const answerQuestions = (questionAnswers) => {
    let newAnswers = Object.assign(allAnswers, questionAnswers);
    setAnswers(newAnswers);
    let matchedStudies = matchStudiesGeneral(STUDIES, newAnswers);

    if (matchedStudies.some(needsClarification)) {
      return "Clarifications";
    } else {
      return "Results";
    }
  }

  const answerClarifications = (clarificationAnswers) => {
    let newAnswers = Object.assign(allAnswers, clarificationAnswers);
    setAnswers(newAnswers);

    let generalMatches = matchStudiesGeneral(STUDIES, newAnswers);
    let matchedStudies = matchStudiesClarifications(generalMatches, newAnswers);
    setMatchedStudies(matchedStudies);
    return "Results";
  }

  const selectStudy = (study) => {
    setSelectedStudy(study);
  }

  const getSelectedStudy = () => {
    return selectedStudy;
  }

  // Load any resources or data that we need prior to rendering the app
  React.useEffect(() => {
    async function loadResourcesAndDataAsync() {
      try {
        SplashScreen.preventAutoHide();

        // Load our initial navigation state
        setInitialNavigationState(await getInitialState());

        // Load fonts
        await Font.loadAsync({
          ...Ionicons.font,
          Roboto: require('native-base/Fonts/Roboto.ttf'),
          Roboto_medium: require('native-base/Fonts/Roboto_medium.ttf'),
          'space-mono': require('./assets/fonts/SpaceMono-Regular.ttf'),
        });


      } catch (e) {
        // We might want to provide this error information to an error reporting service
        console.warn(e);
      } finally {
        setLoadingComplete(true);
        SplashScreen.hide();
      }
    }

    loadResourcesAndDataAsync();
  }, []);

  if (!isLoadingComplete && !props.skipLoadingScreen) {
    return null;
  } else {
    return (
      <View style={styles.container}>
        {Platform.OS === 'ios' && <StatusBar barStyle="default" />}
        <CalculationContext.Provider
          value={{studies: STUDIES, matched: matchedStudies,  answers: allAnswers,
                  answerQuestions: answerQuestions,
                  answerClarifications: answerClarifications,
                  selectStudy: selectStudy,
                  selectedStudy: getSelectedStudy}}
        >
          <NavigationContainer ref={containerRef} initialState={initialNavigationState}>
            <Stack.Navigator>
              <Stack.Screen name="Home" component={HomeScreen} options={{ title: 'Get Started' }}/>
              <Stack.Screen name="Questions" component={QuestionsScreen} />
              <Stack.Screen name="Clarifications" component={ClarificationScreen} />
              <Stack.Screen name="Results" component={ResultScreen} />
              <Stack.Screen name="Links" component={LinksScreen} options={{title: 'Resources' }} />
              <Stack.Screen name="StudyDetails" component={StudyDetailScreen} options={{title: 'Study Details'}}/>
            </Stack.Navigator>
          </NavigationContainer>
        </CalculationContext.Provider>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
});
