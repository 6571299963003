import React, { useState, useContext } from 'react';
import { 
  Text,
  Button,
  StyleSheet
} from 'react-native';
import {
  Container, Content, Form, Item, List,
  ListItem, Input, Label, Left, Right, Radio
} from 'native-base';

import { CalculationContext } from '../CalculationContext';

import { FormStyles } from './FormStyles';
const styles = StyleSheet.create({

  sliderContainerStyle: {
    flex: 1,
    marginLeft: 10,
    marginRight: 10,
    alignItems: "stretch",
    justifyContent: "center"
  },

  sliderStyle: {
    marginLeft: 20,
    marginRight: 20
  }

});

function ExplicitUnknownInput({labelText, setValue, currentValue, testIDPrefix}) {
  return (
    <List>
      <ListItem itemHeader>
        <Label style={FormStyles.label}>{labelText}</Label>
      </ListItem>
      <ListItem testID={testIDPrefix + "Unknown"} onPress={() => setValue("UNKNOWN")}>
          <Left><Text>Unknown</Text></Left>
          <Right><Radio selected={currentValue == "UNKNOWN"} /></Right>
      </ListItem>
      <ListItem testID={testIDPrefix + "Input"} onPress={() => setValue("")}>
          <Left>
            <Input 
              editable={currentValue != "UNKNOWN"}
              testID={testIDPrefix} 
              placeholder="Enter Value" 
              onChangeText={setValue}
              value={currentValue}
              keyboardType="number-pad" />
          </Left>
          <Right>
          </Right>
      </ListItem>
     </List>
  );
}
export default function ClarificationScreen({navigation}) {
  const {matched, answers, answerClarifications} = useContext(CalculationContext);

  const [answer1, setAnswer1] = useState(answers.intubated);
  const [answer2, setAnswer2] = useState(answers.uihcAircare);
  const [answer3, setAnswer3] = useState(answers.strokeCoreSize || "UNKNOWN");
  const [answer4, setAnswer4] = useState(answers.strokePenumbraSize || "UNKNOWN");
  const [redirectPath, setRedirectPath] = useState(null);

  function undefinedToEmpty(val) {
    if (typeof(val) === 'undefined') {
      return '';
    } else {
      return val;
    }
  }

  const answerMap = () => {
    let coreSize = 0.0;
    if (answer3 && answer3 != "UNKNOWN") {
      coreSize = parseFloat(answer3);
    }

    let penumbraSize = 0.0;
    if (answer4 && answer4 != "UNKNOWN") {
      penumbraSize = parseFloat(answer4);
    }

    return {
      intubated: undefinedToEmpty(answer1),
      uihcAircare: undefinedToEmpty(answer2),
      strokeCoreSize: coreSize,
      strokePenumbraSize: penumbraSize,
    };
  }

  const checkClick = () => {
    let answers = answerMap();
    console.log('CLARIFICATIONS: HERE BE ANSWERS');
    console.log(answers);
    let nextPath = answerClarifications(answers);
    navigation.navigate(nextPath);
  }

  if (redirectPath) {
    return <Redirect push to={redirectPath} />
  }

  return (
    <Container>
      <Content>
        <Form>
          <List testID="intubated">
            <ListItem itemHeader>
              <Text style={FormStyles.label}>Intubated</Text>
            </ListItem>
            <ListItem testID="intubatedYes" onPress={() => setAnswer1(true)}>
                <Left><Text>Yes</Text></Left>
                <Right><Radio selected={answer1 == true} /></Right>
            </ListItem>
            <ListItem testID="intubatedNo" onPress={() => setAnswer1(false)}>
                <Left><Text>No</Text></Left>
                <Right><Radio selected={answer1 == false} /></Right>
            </ListItem>
          </List>
          <List testID="uihcAircare">
            <ListItem itemHeader>
              <Text style={FormStyles.label}>Traveling by UIHC Aircare </Text>
            </ListItem>
            <ListItem testID="uihcAircareYes" onPress={() => setAnswer2(true)}>
                <Left><Text>Yes</Text></Left>
                <Right><Radio selected={answer2 == true} /></Right>
            </ListItem>
            <ListItem testID="uihcAircareNo" onPress={() => setAnswer2(false)}>
                <Left><Text>No</Text></Left>
                <Right><Radio selected={answer2 == false} /></Right>
            </ListItem>
          </List>
          <ExplicitUnknownInput
            labelText="Stroke core size (CBF &lt;30%)"
            testIDPrefix="strokeCoreSize"
            currentValue={answer3}
            setValue={setAnswer3} />

          <ExplicitUnknownInput
            labelText="Stroke penumbra size (Tmax &gt;6.0s)"
            testIDPrefix="strokePenumbraSize"
            currentValue={answer4}
            setValue={setAnswer4} />

          <Button testID="clarificationSubmit" title="Submit" onPress={checkClick} />
        </Form>
      </Content>
    </Container>
  );
}
