export const STUDIES = [
    {
      name: 'Sega',
      criteria: {
        age: [18,90],
        lastNormal: [0,16],
        nihssScore: [6,30],
        aspectScore: 'NA',
        largeVesselOcclusion: ['a','b','c'],
        mechanicalThrombectomyPlanned: 'y',
        tpaGiven: 'NA',
        intubated: 'n'
      }
    }

    , {
      name: 'Tesla',
      criteria: {
        age: [18,85],
        lastNormal: [0,24],
        nihssScore: [6, Number.MAX_SAFE_INTEGER],
        aspectScore: [2,5],
        largeVesselOcclusion: ['a','b','c'],
        mechanicalThrombectomyPlanned: 'y',
        tpaGiven: ['a','c']
      }
    }
  
      , {
        name: 'PKM2',
        criteria: {
          age: [18, Number.MAX_SAFE_INTEGER],
          lastNormal: 'NA',
          nihssScore: 'NA',
          aspectScore: [6,10],
          largeVesselOcclusion: ['a','b','c'],
          mechanicalThrombectomyPlanned: 'y',
          tpaGiven: 'NA'
        }
      }
  
        , {
          name: 'Flow Cytometry and Immune Correlates',
          criteria: {
            age: [18, Number.MAX_SAFE_INTEGER],
            lastNormal: 'NA',
            nihssScore: 'NA',
            aspectScore: 'NA',
            largeVesselOcclusion: ['a','c'],
            mechanicalThrombectomyPlanned: 'y',
            tpaGiven: 'NA'
           
          }
        }
  
        , {
          name: 'ICARUS',
          criteria: {
            age: [18, 85],
            lastNormal: 'NA',
            nihssScore: 'NA',
            aspectScore: 'NA',
            largeVesselOcclusion: ['a','b', 'c'],
            mechanicalThrombectomyPlanned: 'NA',
            tpaGiven: 'NA',
            uihcAircare: 'y'
          }
        }
  
        , {
          name: 'PROST',
          criteria: {
            age: [18, Number.MAX_SAFE_INTEGER],
            lastNormal: [0,8],
            nihssScore: [6, Number.MAX_SAFE_INTEGER],
            aspectScore: 'NA',
            largeVesselOcclusion: ['a','b', 'c'],
            mechanicalThrombectomyPlanned: 'y',
            tpaGiven: ['a','c'],
            strokeCoreSize: [0,49]
          }
        }
        , {
          name: 'MOST',
          criteria: {
            age: [18, Number.MAX_SAFE_INTEGER],
            lastNormal: [0,3],
            nihssScore: [6, Number.MAX_SAFE_INTEGER],
            aspectScore: 'NA',
            largeVesselOcclusion: 'NA',
            mechanicalThrombectomyPlanned: 'NA',
            tpaGiven: ['a']
          }
        }
  
        , {
          name: 'SELECT2',
          criteria: {
            age: [18, 85],
            lastNormal: [0,24],
            nihssScore: [6, Number.MAX_SAFE_INTEGER],
            aspectScore: [3,5],
            largeVesselOcclusion: ['a','b','c'],
            mechanicalThrombectomyPlanned: 'y',
            tpaGiven: ['a','c'],
            strokePenumbraSize: [0, 100]
          }
  
        }
  ];

export const getStudyByName = (findName) =>{
    return STUDIES.filter((s) => s.name.toLowerCase() === findName.toLowerCase())
}
