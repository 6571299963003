import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';


import HomeScreen from '../screens/HomeScreen';
import LinksScreen from '../screens/LinksScreen';

const Stack = createStackNavigator();
const INITIAL_ROUTE_NAME = 'Home';
export default function StackNavigator({navigation, route }) {
  navigation.setOptions({headerTitle: getHeaderTitle(route) });

  return (
    <Stack.Navigator initialRouteName={INITIAL_ROUTE_NAME}>
      <Stack.Screen name="Home" component={HomeScreen} options={{
        title: 'Get Started'
      }}/>
      <Stack.Screen name="Links" component={LinksScreen} options={{title: 'Resources' }} />
    </Stack.Navigator>
  );
}

function getHeaderTitle(route) {
  const routeName = route.state?.routes[route.state.index]?.name ?? INITIAL_ROUTE_NAME;

  switch (routeName) {
    case 'Home':
      return 'How to get started';
    case 'Links':
      return 'Links to learn more';
  }
}

