const CLARIFICATIONS = [
  "intubated",
  "uihcAircare",
  "strokeCoreSize",
  "strokePenumbraSize"
];

export function inRange(field, answer, criteria) {
  if (!answer.hasOwnProperty(field)) {
    throw `Answer missing field ${field}`;
  }

  if (!criteria.hasOwnProperty(field)) {
    throw `Criteria missing field ${field}`;
  }

  let answerVal = answer[field];
  let [rangeLow, rangeHigh] = criteria[field];

  if (rangeLow <= answerVal && answerVal <= rangeHigh) {
    return true;
  } else {
    return false;
  }
}

export function allowedValue(field, answer, criteria) {
  if (!answer.hasOwnProperty(field)) {
    throw `Answer missing field ${field}`;
  }

  if (!criteria.hasOwnProperty(field)) {
    throw `Criteria missing field ${field}`;
  }
  let answerKey = [
    'a',
    'b',
    'c',
    'd',
    'e'
  ]

  //the selected index of the choice mapped to a,b,c,d to
  //match up with the spreadsheet definition
  let answerVal = answer[field];
  let allowed = criteria[field];

  if (allowed.includes(answerVal)) {
    return true;
  } else {
    return false;
  }
}
export function isSame(field, answer, criteria) {
  if (!answer.hasOwnProperty(field)) {
    throw `Answer missing field ${field}`;
  }

  if (!criteria.hasOwnProperty(field)) {
    throw `Criteria missing field ${field}`;
  }

  let answerVal = answer[field];
  let neededVal = criteria[field];

  if (answerVal === true) {
    answerVal = 'y';
  } else if (answerVal === false) {
    answerVal = 'n';
  }

  answerVal = answerVal.toLowerCase();
  return answerVal === neededVal;
}

export function isSignificant(field, criteria) {
  if (!criteria.hasOwnProperty(field)) {
    // EAE - If we throw here, then we always have to update every study with each possible field
    //       Instead, we'll assume if missing that is OK and we don't care about it.
    //throw `Criteria missing field ${field}`;
    return false;
  }
  let criteriaVal = criteria[field]
  if (criteriaVal === 'NA') {
    return false;
  } else {
    return true;
  }
}

export function studyQualifiesGeneral(s, answers, checkClarifications) {

  console.log("CHECKING GENERAL", s, answers);
  if (isSignificant('age', s) && !inRange('age', answers, s)) {
    console.log("Disqualified: age");
    return false;
  }

  if (isSignificant('lastNormal',s) && !inRange('lastNormal', answers, s)) {
    console.log("Disqualified: lastNormal");
    return false;
  }

  if (isSignificant('nihssScore', s) && !inRange('nihssScore', answers, s)) {
    console.log("Disqualified: nihssScore");
    return false;
  }

  if (isSignificant('aspectScore', s) && !inRange('aspectScore', answers,s)) {
    console.log("Disqualified: aspectScore");
    return false;
  }

  if (isSignificant('largeVesselOcclusion', s) && !allowedValue('largeVesselOcclusion', answers,s)) {
    console.log("Disqualified: largeVesselOcclusion");
    return false;
  }

  if (isSignificant('mechanicalThrombectomyPlanned', s) && !isSame('mechanicalThrombectomyPlanned', answers,s)) {
    console.log("Disqualified: mechanicalThrombectomyPlanned");
    return false;
  }

  if (isSignificant('tpaGiven', s) && !allowedValue('tpaGiven', answers,s)) {
    console.log("Disqualified: tpaGiven");
    return false;
  }

  console.log("PASS GENERAL");
  return true;
}

export function studyQualifiesClarifications(s, answers) {

  console.log("CHECKING CLARIFICATIONS", s, answers);

  if (isSignificant('intubated', s) && !isSame('intubated', answers, s)) {
    console.log("Disqualified: intubated");
    return false;
  }

  if (isSignificant('uihcAircare', s) && !isSame('uihcAircare', answers, s)) {
    console.log("Disqualified: uihcAircare");
    return false;
  }

  if (isSignificant('strokeCoreSize', s) && !inRange('strokeCoreSize', answers, s)) {
    console.log("Disqualified: strokeCoreSize");
    return false;
  }

  if (isSignificant('strokePenumbraSize', s) && !inRange('strokePenumbraSize', answers, s)) {
    console.log("Disqualified: strokePenumbraSize");
    return false;
  }

  console.log("PASS CLARIFICATIONS")
  return true;
}

function isOdd() {
  var num = arguments[0];
  return num % 2 == 1;
}

export function matchStudiesGeneral(studyList, answers) {
  return studyList.filter((s) => studyQualifiesGeneral(s.criteria, answers));
}

export function matchStudiesClarifications(studyList, answers) {
  return studyList.filter((s) => studyQualifiesClarifications(s.criteria, answers));
}

export function needsClarification(study) {
  const criteria = study.criteria;
  for (const prop of CLARIFICATIONS) {
    if (criteria.hasOwnProperty(prop)) {
      return true;
    }
  }
  return false;
}
