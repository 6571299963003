import React from 'react';
import Slider from './Slider';

export default function ReverseSlider({ labelText, testID, minimumValue, maximumValue, step, onValueChange,
    onSlidingComplete, value, leftColor, rightColor }) {

    let optionalProps = {}

    if (leftColor) {
        optionalProps.trackStyle = {
            backgroundColor: leftColor
        }
    }

    if(rightColor) {
        optionalProps.railStyle = {
            backgroundColor: rightColor
        }
    }

    return (
        <Slider reverse
            labelText={labelText}
            testID={testID}
            minimumValue={minimumValue}
            maximumValue={maximumValue}
            step={step}
            onValueChange={onValueChange}
            onSlidingComplete={onSlidingComplete}
            value={value}
            {...optionalProps}
        />
    );
}